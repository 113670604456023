import {
  INCREMENT_LIGHTBOX_KEY,
  SET_ACTIVE_SCENE_ID,
  SET_LIGHTBOX_SOURCE_INDEX,
  SET_LIGHTBOX_SOURCES,
  SET_ACTIVE_MODAL,
  SET_MODAL_TEMPLATES,
  TOGGLE_LIGHTBOX_STATE,
} from "../constants/ActionTypes";

// Action creators for the Lightbox state.
const incrementLightboxKey = () => ({
  type: INCREMENT_LIGHTBOX_KEY,
});

const setLightboxSourceIndex = (sourceIndex) => ({
  type: SET_LIGHTBOX_SOURCE_INDEX,
  sourceIndex,
});

const setLightboxSources = (sources) => ({
  type: SET_LIGHTBOX_SOURCES,
  sources,
});

const toggleLightboxState = () => ({
  type: TOGGLE_LIGHTBOX_STATE,
});

// Action creators for the Modal state.
const setActiveModal = (modalId) => ({
  type: SET_ACTIVE_MODAL,
  modalId,
});

const setModalTemplates = (modalTemplates) => ({
  type: SET_MODAL_TEMPLATES,
  modalTemplates,
});

// Action creators for the Virtual Consultation Room state.
const setActiveSceneId = (sceneId) => ({
  type: SET_ACTIVE_SCENE_ID,
  sceneId,
});

export {
  incrementLightboxKey,
  setActiveSceneId,
  setLightboxSourceIndex,
  setLightboxSources,
  setActiveModal,
  setModalTemplates,
  toggleLightboxState,
};
