import React, { Component } from "react";

import PropTypes from "prop-types";
import { Nav, Navbar } from "react-bootstrap";
import { HouseFill, ShareFill } from "react-bootstrap-icons";

import Link from "components/Links/Link";
import { navConfig } from "src/app-config";

import NavLogo from "./NavLogo";
import NavTitle from "./NavTitle";

// Add icons here to make them available for use in the navbar.
const icons = {
  HouseFill,
  ShareFill,
};

const navItemFromConf = (navItemConfig, key) => {
  if (navItemConfig.item === "logo") {
    return (
      <NavLogo
        key={key}
        filename={navItemConfig.filename}
        imgStyle={navItemConfig.style}
      />
    );
  } else if (navItemConfig.item === "title") {
    return <NavTitle key={key} title={navItemConfig.text} />;
  } else if (navItemConfig.item === "modal-link") {
    return (
      <Nav.Item key={key} className="nav-spaced nav-modal-link">
        <Link targetModalId={navItemConfig.modalId}>{navItemConfig.text}</Link>
      </Nav.Item>
    );
  } else if (navItemConfig.item === "icon") {
    const NavIcon = icons[navItemConfig.icon];
    return (
      <Nav.Item key={key} className="nav-spaced nav-icon">
        <Link path={navItemConfig.link} targetModalId={navItemConfig.modalId}>
          <NavIcon />
        </Link>
      </Nav.Item>
    );
  }
  // This is a normal page link
  return (
    <Nav.Item key={key} className="nav-spaced nav-page-link">
      <Link path={navItemConfig.link}>{navItemConfig.text}</Link>
    </Nav.Item>
  );
};

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navExpanded: false,
    };
  }

  setNavExpanded = (expanded) => {
    this.setState({ navExpanded: expanded });
  };

  closeNav = () => {
    this.setState({ navExpanded: false });
  };

  render() {
    return (
      <Navbar
        expand="lg"
        bg="light"
        className="main-nav"
        onToggle={this.setNavExpanded}
        expanded={this.state.navExpanded}
      >
        {navConfig.items.nonCollapsing.map((navItemConfig, index) =>
          navItemFromConf(navItemConfig, `prebar-${index}`)
        )}
        <Navbar.Toggle aria-controls="navbar-nav" />
        <Navbar.Collapse id="navbar-nav">
          <Nav className="navbar-links" onClick={this.closeNav}>
            {navConfig.items.collapsing.map((navItemConfig, index) =>
              navItemFromConf(navItemConfig, `postbar-${index}`)
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

NavBar.propTypes = {
  page: PropTypes.string.isRequired,
};

export default NavBar;
