/**
 * Simple component to scroll to the top via vanilla JS. The scroll button is
 * hidden until the page has scrolled down via the `margin`.
 *
 * If the `colour` prop is omitted, then the colour of the icon is inherited
 * from the `a` tag styling set for the site.
 */
import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import { ArrowUpCircleFill } from "react-bootstrap-icons";

const ScrollToTop = (props) => {
  const [visible, setVisible] = useState(false);

  const handleScroll = () => {
    setVisible(document.documentElement.scrollTop > props.margin);
  };

  useEffect(() => {
    document.addEventListener("scroll", handleScroll, { passive: true });
    // Remove the event listener when the component is unmounted.
    return () => document.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      {visible && (
        <a
          href="#top"
          className="scroll-to-top-button"
          onClick={() => {
            document.documentElement.scrollTop = 0;
          }}
        >
          <ArrowUpCircleFill color={props.colour} size={props.size} />
        </a>
      )}
    </>
  );
};

ScrollToTop.propTypes = {
  colour: PropTypes.string,
  margin: PropTypes.number,
  size: PropTypes.number,
};

ScrollToTop.defaultProps = {
  margin: 200,
  size: 40,
};

export default ScrollToTop;
