/**
 * A Higher Order Component that wraps a component and provides access to the
 * Redux store for accessing and modifying the lightbox state.
 *
 * This adds on prop to the wrapped component:
 * - triggerLightbox: A memoized function that will set the lightbox sources
 *     and will launch the lightbox.
 *
 * Wrapped components MUST specify the `lightboxSources` and
 * `startingLightboxIndex` props so the lightbox can be triggered successfully.
 */
import React, { useCallback } from "react";

import { useDispatch } from "react-redux";

import {
  incrementLightboxKey,
  setLightboxSourceIndex,
  setLightboxSources,
  toggleLightboxState,
} from "state/actions";

export default function withLightboxTrigger(WrappedComponent) {
  return function WrappedComponentWithLightboxTrigger(props) {
    const dispatch = useDispatch();

    const triggerLightbox = useCallback(() => {
      dispatch(setLightboxSources(props.lightboxSources));
      dispatch(setLightboxSourceIndex(props.startingLightboxIndex));
      dispatch(incrementLightboxKey());
      // FsLightbox watches for CHANGES to the `toggler` prop, not the value of
      // the prop. If we rerender the lightbox (by changing the key) after we
      // have toggled the lightbox state then it FsLightbox won't be aware of
      // the change and won't trigger. Delay the change of the toggle state until
      // the "next tick" to let the FsLightbox component notice the change.
      setTimeout(() => {
        dispatch(toggleLightboxState());
      });
    }, [dispatch]);

    return <WrappedComponent triggerLightbox={triggerLightbox} {...props} />;
  };
}
