import { constructionPhotos, operationPhotos } from "./mapData/montagePoints";

export const generalConfig = {
  googleTranslate: {
    enabled: true,
    languages: [
      { text: "لغة", code: "ar" },
      { text: "语言", code: "zh-CN" },
      { text: "Γλώσσα", code: "el" },
      { text: "Linguaggio", code: "it" },
      { text: "언어", code: "ko" },
      { text: "Ngôn ngữ", code: "vi" },
    ],
  },
};

export const mapConfig = {
  commonLegend: [
    {
      legendType: "img",
      colour: "",
      text: "Metro Station",
      value: "metro-station-legend",
    },
    {
      legendType: "img",
      colour: "",
      text: "Services facility",
      value: "services-facility-legend",
    },
    {
      legendType: "img",
      colour: "",
      text: "Stabling and maintenance facility",
      value: "stabling-facility-legend",
    },
    {
      legendType: "line",
      colour: "rgb(99, 189, 10)",
      text: "Metro Alignment (Surface)",
      value: "solid",
    },
    {
      legendType: "line",
      colour: "rgb(21, 148, 153)",
      text: "Metro Alignment (Tunnel)",
      value: "dotted",
    },
    {
      legendType: "line",
      colour: "rgb(197, 32, 62)",
      text: "Metro Alignment (in cutting)",
      value: "solid",
    },
    {
      legendType: "line",
      colour: "rgb(205, 86, 153)",
      text: "Metro Alignment (cut and cover)",
      value: "solid",
    },
    {
      legendType: "line",
      colour: "rgb(27, 118, 187)",
      text: "Metro Alignment (viaduct)",
      value: "solid",
    },
    {
      legendType: "line",
      colour: "rgb(66, 245, 245)",
      text: "Power supply routes",
      value: "dotted",
    },
    {
      legendType: "icon",
      colour: "#7d7d7d",
      text: "Construction Footprint",
      value: "SquareFill",
    },
    {
      legendType: "icon",
      colour: "#000000",
      text: "Warragamba to Prospect Water Supply Pipelines",
      value: "Square",
    },
    {
      legendType: "icon",
      colour: "#fc0303",
      text: "M12 Motorway",
      value: "SquareFill",
    },
    {
      legendType: "icon",
      colour: "#f4d7eb",
      text: "Western Sydney International",
      value: "SquareFill",
    },
  ],
  geocoderConfig: {
    moduleComponentName: "MapboxGeocoder",
    searchBoundary: {
      minX: 150.281748,
      minY: -34.135641,
      maxX: 151.121232,
      maxY: -33.515657,
    },
  },
  icons: [
    {
      id: "ocp-custom-metro-icon-mapbox",
      fileName: "metro-station.png",
    },
    {
      id: "ocp-custom-services-facility-icon-mapbox",
      fileName: "services-facility.png",
    },
    {
      id: "ocp-custom-stabling-facility-icon-mapbox",
      fileName: "stabling-facility.png",
    },
    {
      id: "ocp-custom-camera-construction-icon-mapbox",
      fileName: "camera-construction.png",
    },
    {
      id: "ocp-custom-camera-operation-icon-mapbox",
      fileName: "camera-operation.png",
    },
  ],
  layerControls: [
    {
      ids: [
        "Proposed-metro-station",
        "services-facility",
        "stabling-facility",
        "design-site-names",
      ],
      label: "Metro stations",
    },
    {
      ids: [
        "Proposed-metro-alignment-In-cutting",
        "Proposed-metro-alignment-Cut-Cover",
        "Proposed-metro-alignment-Bridge-Viaduct",
        "Proposed-metro-alignment-Surface",
        "Proposed-metro-alignment-Tunnel",
      ],
      label: "Metro alignment",
    },
    {
      ids: ["Construction-footprint", "construction-labels"],
      label: "Construction footprint",
    },
    {
      ids: [
        "Warragamba-to-Prospect-Water-Supply-Pipeline-Label",
        "Warragamba-to-Prospect-Water-Supply-Pipeline",
      ],
      label: "Warragamba to Prospect Water Supply Pipelines",
    },
    {
      ids: ["M12-Motorway"],
      label: "M12 Motorway",
    },
    {
      ids: ["Claremont-Meadows-power-supply-route"],
      label: "Claremont Meadows power supply route",
    },
    {
      ids: ["Kemps-Creek-power-supply-route"],
      label: "Kemps Creek power supply route",
    },
    {
      ids: ["Western-Sydney-International"],
      label: "Western Sydney International",
    },
  ],
  layers: [
    {
      id: "design-site-names",
      mapboxStyle: "mapbox://styles/create-anz/ckfyl9uz40anb19jz2t783vmb",
      "source-layer": "stations-facilities-sept21-6fnuvw",
      type: "symbol",
      source: {
        type: "vector",
        url: "mapbox://create-anz.9vay721i",
      },
    },
    {
      id: "Noise-catchment-areas-labels",
      mapboxStyle: "mapbox://styles/create-anz/ckfymu7sl0a4u19o8emte4ai5",
      "source-layer": "NOISE_WSP_NoiseCatchmentAreas-0gba1i",
      type: "symbol",
      source: {
        type: "vector",
        url: "mapbox://create-anz.6uomgo4m",
      },
    },
    {
      id: "construction-labels",
      mapboxStyle: "mapbox://styles/create-anz/ckfynqspz09i919qqogkiihft",
      "source-layer": "construction-labels-3jdjb1",
      type: "symbol",
      source: {
        type: "vector",
        url: "mapbox://create-anz.686qm0oa",
      },
    },
    {
      id: "airport-label",
      mapboxStyle: "mapbox://styles/create-anz/ckfyl9uz40anb19jz2t783vmb",
      "source-layer": "AirportLabel-dy9j7b",
      type: "symbol",
      source: {
        type: "vector",
        url: "mapbox://create-anz.0h7j260o",
      },
    },
    {
      id: "airport-symbol",
      "source-layer": "AirportLabel-dy9j7b",
      type: "symbol",
      source: {
        type: "vector",
        url: "mapbox://create-anz.0h7j260o",
      },
      layout: {
        "icon-image": "airport-15",
        "icon-allow-overlap": true,
        "icon-size": 1,
      },
      paint: {
        "icon-opacity": {
          stops: [
            [10, 0],
            [11, 1],
          ],
        },
      },
    },
    {
      id: "Proposed-metro-station",
      "source-layer": "Stations-3i2m8k",
      type: "symbol",
      source: {
        type: "vector",
        url: "mapbox://create-anz.8s32dyrs",
      },
      layout: {
        "icon-image": "ocp-custom-metro-icon-mapbox",
        "icon-allow-overlap": true,
        "icon-size": 0.7,
      },
      paint: {
        "icon-opacity": {
          stops: [
            [9, 0],
            [10, 1],
          ],
        },
      },
    },
    {
      id: "services-facility",
      type: "symbol",
      source: {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [150.724375, -33.907259],
              },
            },
            {
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [150.753679, -33.769078],
              },
            },
          ],
        },
      },
      layout: {
        "icon-image": "ocp-custom-services-facility-icon-mapbox",
        "icon-allow-overlap": true,
        "icon-size": 0.6,
      },
      paint: {
        "icon-opacity": {
          stops: [
            [9, 0],
            [10, 1],
          ],
        },
      },
    },
    {
      id: "stabling-facility",
      type: "symbol",
      source: {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [150.752776, -33.804616],
          },
        },
      },
      layout: {
        "icon-image": "ocp-custom-stabling-facility-icon-mapbox",
        "icon-allow-overlap": true,
        "icon-size": 0.6,
      },
      paint: {
        "icon-opacity": {
          stops: [
            [9, 0],
            [10, 1],
          ],
        },
      },
    },
    {
      id: "construction-photo-montage-points",
      type: "symbol",
      source: {
        type: "geojson",
        data: constructionPhotos,
      },
      layout: {
        "icon-image": "ocp-custom-camera-construction-icon-mapbox",
        "icon-allow-overlap": true,
        "icon-size": 0.4,
      },
      paint: {
        "icon-opacity": {
          stops: [
            [9, 0],
            [10, 1],
          ],
        },
      },
    },
    {
      id: "operations-photo-montage-points",
      type: "symbol",
      source: {
        type: "geojson",
        data: operationPhotos,
      },
      layout: {
        "icon-image": "ocp-custom-camera-operation-icon-mapbox",
        "icon-allow-overlap": true,
        "icon-size": 0.4,
      },
      paint: {
        "icon-opacity": {
          stops: [
            [9, 0],
            [10, 1],
          ],
        },
      },
    },
    {
      id: "Proposed-metro-alignment-Surface",
      mapboxStyle: "mapbox://styles/create-anz/ckfyl9uz40anb19jz2t783vmb",
      "source-layer": "DESIGN_WSP_ProposedMetroAlign-6gpc99",
      type: "line",
      source: {
        type: "vector",
        url: "mapbox://create-anz.a84b3xxw",
      },
    },
    {
      id: "Proposed-metro-alignment-In-cutting",
      mapboxStyle: "mapbox://styles/create-anz/ckfyl9uz40anb19jz2t783vmb",
      "source-layer": "DESIGN_WSP_ProposedMetroAlign-6gpc99",
      type: "line",
      source: {
        type: "vector",
        url: "mapbox://create-anz.a84b3xxw",
      },
    },
    {
      id: "Proposed-metro-alignment-Cut-Cover",
      mapboxStyle: "mapbox://styles/create-anz/ckfyl9uz40anb19jz2t783vmb",
      "source-layer": "DESIGN_WSP_ProposedMetroAlign-6gpc99",
      type: "line",
      source: {
        type: "vector",
        url: "mapbox://create-anz.a84b3xxw",
      },
    },
    {
      id: "Proposed-metro-alignment-Bridge-Viaduct",
      mapboxStyle: "mapbox://styles/create-anz/ckfyl9uz40anb19jz2t783vmb",
      "source-layer": "DESIGN_WSP_ProposedMetroAlign-6gpc99",
      type: "line",
      source: {
        type: "vector",
        url: "mapbox://create-anz.a84b3xxw",
      },
    },
    {
      id: "Proposed-metro-alignment-Tunnel",
      mapboxStyle: "mapbox://styles/create-anz/ckfyl9uz40anb19jz2t783vmb",
      "source-layer": "DESIGN_WSP_ProposedMetroAlign-6gpc99",
      type: "line",
      source: {
        type: "vector",
        url: "mapbox://create-anz.a84b3xxw",
      },
    },
    {
      id: "Claremont-Meadows-power-supply-route",
      mapboxStyle: "mapbox://styles/create-anz/ckfyl9uz40anb19jz2t783vmb",
      "source-layer": "UTIL_WSP_ClaremontMeadowsPowe-7f90k9",
      type: "line",
      source: {
        type: "vector",
        url: "mapbox://create-anz.0l377xpm",
      },
    },
    {
      id: "Kemps-Creek-power-supply-route",
      mapboxStyle: "mapbox://styles/create-anz/ckfyl9uz40anb19jz2t783vmb",
      "source-layer": "UTIL_WSP_KempsCreekPowerSuppl-2875i9",
      type: "line",
      source: {
        type: "vector",
        url: "mapbox://create-anz.6bru7nuy",
      },
    },
    {
      id: "Warragamba-to-Prospect-Water-Supply-Pipeline-Label",
      mapboxStyle: "mapbox://styles/create-anz/ckfyl9uz40anb19jz2t783vmb",
      "source-layer": "pipeline-label-9kth7d",
      type: "symbol",
      source: {
        type: "vector",
        url: "mapbox://create-anz.1ywrw347",
      },
    },
    {
      id: "Warragamba-to-Prospect-Water-Supply-Pipeline",
      mapboxStyle: "mapbox://styles/create-anz/ckfyl9uz40anb19jz2t783vmb",
      "source-layer": "HER_WSP_Section170HeritageReg-c81s86",
      type: "line",
      source: {
        type: "vector",
        url: "mapbox://create-anz.8m4ro6x7",
      },
    },
    {
      id: "Section-170-heritage-register",
      mapboxStyle: "mapbox://styles/create-anz/ckfynqspz09i919qqogkiihft",
      "source-layer": "HER_WSP_Section170HeritageReg-c81s86",
      type: "line",
      source: {
        type: "vector",
        url: "mapbox://create-anz.8m4ro6x7",
      },
    },
    {
      id: "5AEP-Flooding",
      mapboxStyle: "mapbox://styles/create-anz/ckfymu7sl0a4u19o8emte4ai5",
      "source-layer": "HYDRO_WSP_UpEx02AllQ020MaxDEn-5lpgy7",
      type: "fill",
      source: {
        type: "vector",
        url: "mapbox://create-anz.1bigc7n6",
      },
    },
    {
      id: "peak-flood-level-impact",
      mapboxStyle: "mapbox://styles/create-anz/ckfymu7sl0a4u19o8emte4ai5",
      "source-layer": "flood-aep-1perc-18erje",
      type: "fill",
      source: {
        type: "vector",
        url: "mapbox://create-anz.4iokv7h9",
      },
    },
    {
      id: "Western-Sydney-International-Stage-1",
      mapboxStyle: "mapbox://styles/create-anz/ckfynqspz09i919qqogkiihft",
      "source-layer": "PLANNING_WSP_WSIStage1Constru-4840h6",
      type: "line",
      source: {
        type: "vector",
        url: "mapbox://create-anz.ag3471u1",
      },
    },
    {
      id: "Threatened-Ecological-Communities-BC-Act",
      mapboxStyle: "mapbox://styles/create-anz/ckfynqspz09i919qqogkiihft",
      "source-layer": "TEC-BCAct-3n6qle",
      type: "fill",
      source: {
        type: "vector",
        url: "mapbox://create-anz.604zx3dl",
      },
    },
    {
      id: "Threatened-Ecological-Communities-EPBC",
      mapboxStyle: "mapbox://styles/create-anz/ckfynqspz09i919qqogkiihft",
      "source-layer": "TEC-EPBCAct-ak9xzn",
      type: "fill",
      source: {
        type: "vector",
        url: "mapbox://create-anz.6jn73bmr",
      },
    },
    {
      id: "Noise-catchment-areas",
      mapboxStyle: "mapbox://styles/create-anz/ckfymu7sl0a4u19o8emte4ai5",
      "source-layer": "NOISE_WSP_NoiseCatchmentAreas-0gba1i",
      type: "fill",
      source: {
        type: "vector",
        url: "mapbox://create-anz.6uomgo4m",
      },
    },
    {
      id: "noise-reciever-typical-worst-affected",
      mapboxStyle: "mapbox://styles/create-anz/ckfymu7sl0a4u19o8emte4ai5",
      "source-layer": "noise-reciever-typical-affect-32zggt",
      type: "line",
      source: {
        type: "vector",
        url: "mapbox://create-anz.cfvecd6j",
      },
    },
    {
      id: "Buildings-within-minimum-working-distance-for-cosmetic-damage",
      mapboxStyle: "mapbox://styles/create-anz/ckfymu7sl0a4u19o8emte4ai5",
      "source-layer": "NOISE_WSP_BuildingsWithinMini-99bedy",
      type: "fill",
      source: {
        type: "vector",
        url: "mapbox://create-anz.2ca36lpa",
      },
    },
    {
      id: "Construction-noise-vibration",
      mapboxStyle: "mapbox://styles/create-anz/ckfymu7sl0a4u19o8emte4ai5",
      "source-layer": "NOISE_WSP_OffAirHighlyNoiseAf-1h2kv4",
      type: "fill",
      source: {
        type: "vector",
        url: "mapbox://create-anz.5gr79d2o",
      },
    },
    {
      id: "noise-on-air-operation",
      mapboxStyle: "mapbox://styles/create-anz/ckfymu7sl0a4u19o8emte4ai5",
      "source-layer": "noise-on-air-operation-06vgs7",
      type: "line",
      source: {
        type: "vector",
        url: "mapbox://create-anz.9nq1riqg",
      },
    },
    {
      id: "noise-off-airport-operation",
      mapboxStyle: "mapbox://styles/create-anz/ckfymu7sl0a4u19o8emte4ai5",
      "source-layer": "noise-off-airport-operation-75tdhp",
      type: "line",
      source: {
        type: "vector",
        url: "mapbox://create-anz.8vwe39hi",
      },
    },
    {
      id: "noise-stabling-impact",
      mapboxStyle: "mapbox://styles/create-anz/ckfymu7sl0a4u19o8emte4ai5",
      "source-layer": "noise-stabling-impact-3dzvfx",
      type: "fill",
      source: {
        type: "vector",
        url: "mapbox://create-anz.7cfrbjh9",
      },
    },
    {
      id: "Indicative-haulage-routes",
      mapboxStyle: "mapbox://styles/create-anz/ckfymu7sl0a4u19o8emte4ai5",
      "source-layer": "TRANS_WSP_IndicativeHaulageRo-4pwpzt",
      type: "line",
      source: {
        type: "vector",
        url: "mapbox://create-anz.d30rjg3u",
      },
    },
    {
      id: "Bus-interchange-stop-or-layover",
      mapboxStyle: "mapbox://styles/create-anz/ckfymu7sl0a4u19o8emte4ai5",
      "source-layer": "PLANNING_WSP_BusInterchangeSt-a9pyk4",
      type: "fill",
      source: {
        type: "vector",
        url: "mapbox://create-anz.106d5iuq",
      },
    },
    {
      id: "planning-wsp-constructionsite",
      mapboxStyle: "mapbox://styles/create-anz/ckfynqspz09i919qqogkiihft",
      "source-layer": "planning-wsp-constructionsite-baycdi",
      type: "fill",
      source: {
        type: "vector",
        url: "mapbox://create-anz.8y5owz5k",
      },
    },
    {
      id: "State-heritage-register",
      mapboxStyle: "mapbox://styles/create-anz/ckfynqspz09i919qqogkiihft",
      "source-layer": "state-heritage-bfyprv",
      type: "line",
      source: {
        type: "vector",
        url: "mapbox://create-anz.4xflzadf",
      },
    },
    {
      id: "Liverpool-LEP-2008",
      mapboxStyle: "mapbox://styles/create-anz/ckfynqspz09i919qqogkiihft",
      "source-layer": "liverpool-lep-2008-d6uey3",
      type: "fill",
      source: {
        type: "vector",
        url: "mapbox://create-anz.df98vfy4",
      },
    },
    {
      id: "Construction-footprint",
      mapboxStyle: "mapbox://styles/create-anz/ckfynqspz09i919qqogkiihft",
      "source-layer": "DESIGN_WSP_ConstructionSites_-8cdsmj",
      type: "fill",
      source: {
        type: "vector",
        url: "mapbox://create-anz.ath45mg0",
      },
    },
    {
      id: "Character-areas",
      mapboxStyle: "mapbox://styles/create-anz/ckfymu7sl0a4u19o8emte4ai5",
      "source-layer": "DESIGN_WSP_LandscapeCharacter-bnywp3",
      type: "fill",
      source: {
        type: "vector",
        url: "mapbox://create-anz.ai0pfesp",
      },
    },
    {
      id: "Potential-heritage-items",
      mapboxStyle: "mapbox://styles/create-anz/ckfynqspz09i919qqogkiihft",
      "source-layer": "HER_WSP_PotentialHeritageItem-0pxvjq",
      type: "fill",
      source: {
        type: "vector",
        url: "mapbox://create-anz.88tdd8v9",
      },
    },
    {
      id: "Penrith-LEP-2010",
      mapboxStyle: "mapbox://styles/create-anz/ckfynqspz09i919qqogkiihft",
      "source-layer": "HER_WSP_PenrithLEP2010_201007-2dg6qw",
      type: "fill",
      source: {
        type: "vector",
        url: "mapbox://create-anz.7h8plob9",
      },
    },
    {
      id: "M12-Motorway",
      mapboxStyle: "mapbox://styles/create-anz/ckfyl9uz40anb19jz2t783vmb",
      "source-layer": "m12-operation-footprint-c364ez",
      type: "fill",
      source: {
        type: "vector",
        url: "mapbox://create-anz.6krst5ud",
      },
    },
    {
      id: "South-West-Growth-Centre-and-on-off-airport-delineation",
      mapboxStyle: "mapbox://styles/create-anz/ckfymu7sl0a4u19o8emte4ai5",
      "source-layer": "PLANNING_WSP_SouthWestGrowthC-djj0yv",
      type: "fill",
      source: {
        type: "vector",
        url: "mapbox://create-anz.a5s9o7bx",
      },
    },
    {
      id: "Western-Sydney-International",
      mapboxStyle: "mapbox://styles/create-anz/ckfyl9uz40anb19jz2t783vmb",
      "source-layer": "BOUNDARY_WSP_WesternSydneyInt-b1g591",
      type: "fill",
      source: {
        type: "vector",
        url: "mapbox://create-anz.bm8pww68",
      },
    },
    {
      id: "South-West-Growth-Centres-Strategic-Assessment",
      mapboxStyle: "mapbox://styles/create-anz/ckfymu7sl0a4u19o8emte4ai5",
      "source-layer": "ENV_WSP_SouthWestGrowthCentre-9zqhg3",
      type: "fill",
      source: {
        type: "vector",
        url: "mapbox://create-anz.5pnd0gyg",
      },
    },
    {
      id: "mapbox-satellite-imagery",
      type: "raster",
      source: {
        type: "raster",
        url: "mapbox://mapbox.satellite",
        tileSize: 256,
      },
      paint: {
        "raster-opacity": 0.8,
      },
    },
  ],
  layerLegends: {
    "construction-photo-montage-points": [
      {
        legendType: "img",
        colour: "",
        text: "Construction photomontages",
        value: "camera-construction-legend",
      },
    ],
    "operations-photo-montage-points": [
      {
        legendType: "img",
        colour: "",
        text: "Operations photomontages",
        value: "camera-operation-legend",
      },
    ],
    "planning-wsp-constructionsite": [
      {
        legendType: "icon",
        colour: "#f3e5b4",
        text: "Laydown",
        value: "SquareFill",
      },
      {
        legendType: "icon",
        colour: "#d2f8b5",
        text: "Office",
        value: "SquareFill",
      },
      {
        legendType: "icon",
        colour: "#81efe2",
        text: "TBM extraction shaft",
        value: "SquareFill",
      },
      {
        legendType: "icon",
        colour: "#e78383",
        text: "Parking",
        value: "SquareFill",
      },
      {
        legendType: "icon",
        colour: "#e69833",
        text: "Plant and equipment storage",
        value: "SquareFill",
      },
      {
        legendType: "icon",
        colour: "#7993e2",
        text: "Excavation",
        value: "SquareFill",
      },
      {
        legendType: "icon",
        colour: "#2610ea",
        text: "Water treatment plant",
        value: "SquareFill",
      },
      {
        legendType: "icon",
        colour: "#a66908",
        text: "Spoil handling",
        value: "SquareFill",
      },
      {
        legendType: "icon",
        colour: "#70edfb",
        text: "TBM access shaft",
        value: "SquareFill",
      },
      {
        legendType: "icon",
        colour: "#f392f6",
        text: "Other",
        value: "SquareFill",
      },
    ],
    "South-West-Growth-Centre-and-on-off-airport-delineation": [
      {
        legendType: "icon",
        colour: "#fab575",
        text: "Off airport",
        value: "SquareFill",
      },
      {
        legendType: "icon",
        colour: "#5bd7aa",
        text: "On airport",
        value: "SquareFill",
      },
    ],
    "Indicative-haulage-routes": [
      {
        legendType: "line",
        colour: "#EDC982",
        text: "Indicative haulage routes",
        value: "solid",
      },
    ],
    "Bus-interchange-stop-or-layover": [
      {
        legendType: "icon",
        colour: "#9910f4",
        text: "Relocation of bus interchange stop and/or layover at St Marys",
        value: "SquareFill",
      },
    ],
    "Noise-catchment-areas": [
      {
        legendType: "icon",
        colour: "#ffaa00",
        text: "Noise catchment areas",
        value: "Square",
      },
    ],
    "Buildings-within-minimum-working-distance-for-cosmetic-damage": [
      {
        legendType: "icon",
        colour: "#4162f6",
        text:
          "Buildings within minimum working distances for cosmetic damage - non-residential receiver",
        value: "SquareFill",
      },
    ],
    "Construction-noise-vibration": [
      {
        legendType: "img",
        colour: "",
        text:
          "Buildings within minimum working distances for cosmetic damage - residential receiver",
        value: "orange-box-blue-border",
      },
      {
        legendType: "icon",
        colour: "#ff5500",
        text:
          "Highly noise affected residential receiver during worst case construction",
        value: "SquareFill",
      },
    ],
    "Western-Sydney-International-Stage-1": [
      {
        legendType: "line",
        colour: "#000000",
        text: "Western Sydney International Stage 1 Construction Impact Zone",
        value: "dotted",
      },
    ],
    "Threatened-Ecological-Communities-BC-Act": [
      {
        legendType: "icon",
        colour: "#FFFFFF",
        text: "Threatened Ecological Communities",
        value: "SquareFill",
      },
      {
        legendType: "icon",
        colour: "#70fae1",
        text:
          "Cumberland Plain Shale Woodlands and Shale-Gravel Transition Forest (Critically Endangered) BC Act",
        value: "SquareFill",
      },
      {
        legendType: "icon",
        colour: "#08d4ae",
        text:
          "Coastal Swamp Oak (Casuarina glauca) Forest of New South Wales and South East Queensland (Endangered) BC Act",
        value: "SquareFill",
      },
    ],
    "Threatened-Ecological-Communities-EPBC": [
      {
        legendType: "icon",
        colour: "#1f7b0f",
        text:
          "Coastal Swamp Oak (Casuarina glauca) Forest of New South Wales and South East Queensland (Endangered) EPBC Act",
        value: "SquareFill",
      },
      {
        legendType: "icon",
        colour: "#0fe653",
        text:
          "Cumberland Plain Shale Woodlands and Shale-Gravel Transition Forest (Critically Endangered) EPBC Act",
        value: "SquareFill",
      },
    ],
    "State-heritage-register": [
      {
        legendType: "icon",
        colour: "#fefb39",
        text: "State heritage register",
        value: "Square",
      },
    ],
    "Section-170-heritage-register": [
      {
        legendType: "icon",
        colour: "#000000",
        text: "Section 170 heritage register",
        value: "Square",
      },
    ],
    "Penrith-LEP-2010": [
      {
        legendType: "icon",
        colour: "#fa85e6",
        text: "Penrith LEP 2010",
        value: "SquareFill",
      },
    ],
    "Liverpool-LEP-2008": [
      {
        legendType: "icon",
        colour: "#fa85e6",
        text: "Liverpool LEP 2008",
        value: "SquareFill",
      },
    ],
    "Potential-heritage-items": [
      {
        legendType: "icon",
        colour: "#74bdf1",
        text: "Potential heritage items",
        value: "SquareFill",
      },
    ],
    "5AEP-Flooding": [
      {
        legendType: "icon",
        colour: "#ffffff",
        text: "5% AEP flood depth (m)",
        value: "SquareFill",
      },
      {
        legendType: "icon",
        colour: "",
        text: "0-0.1 m",
        value: "Square",
      },
      {
        legendType: "icon",
        colour: "#aadbf3",
        text: "0.1-0.5 m",
        value: "SquareFill",
      },
      {
        legendType: "icon",
        colour: "#5384df",
        text: "0.5-1 m",
        value: "SquareFill",
      },
      {
        legendType: "icon",
        colour: "#2b33a1",
        text: ">1.0 m",
        value: "SquareFill",
      },
    ],
    "peak-flood-level-impact": [
      {
        legendType: "icon",
        colour: "#ffffff",
        text: "1% AEP - Peak flood level impact (mm)",
        value: "SquareFill",
      },
      {
        legendType: "icon",
        colour: "#bde8ff",
        text: "< -10 mm",
        value: "SquareFill",
      },
      {
        legendType: "icon",
        colour: "#3b6ba5",
        text: "-10mm - 10mm",
        value: "SquareFill",
      },
      {
        legendType: "icon",
        colour: "#99e600",
        text: "10mm - 50mm",
        value: "SquareFill",
      },
      {
        legendType: "icon",
        colour: "#ffffbd",
        text: "50mm - 200mm",
        value: "SquareFill",
      },
      {
        legendType: "icon",
        colour: "#ffd280",
        text: ">200 mm",
        value: "SquareFill",
      },
    ],
    "Character-areas": [
      {
        legendType: "icon",
        colour: "#da96e3",
        text: "St Marys town centre character area",
        value: "SquareFill",
      },
      {
        legendType: "icon",
        colour: "#59a6d9",
        text: "St Marys south suburban fringe character area",
        value: "SquareFill",
      },
      {
        legendType: "icon",
        colour: "#efad43",
        text: "Orchard Hills rural landscape character area",
        value: "SquareFill",
      },
      {
        legendType: "icon",
        colour: "#77dfc5",
        text: "Luddenham rural landscape character area",
        value: "SquareFill",
      },
      {
        legendType: "icon",
        colour: "#f0e7c6",
        text: "Western Sydney International character area",
        value: "SquareFill",
      },
      {
        legendType: "icon",
        colour: "#48c144",
        text: "Bringelly rural landscape character area",
        value: "SquareFill",
      },
    ],
    "South-West-Growth-Centres-Strategic-Assessment": [
      {
        legendType: "icon",
        colour: "rgba(239, 253, 231, 0.8)",
        text: "South West Growth Centre",
        value: "SquareFill",
      },
    ],
    "noise-on-air-operation": [
      {
        legendType: "icon",
        colour: "#FFF",
        text: "On-airport airborne operational rail noise criteria",
        value: "SquareFill",
      },
      {
        legendType: "icon",
        colour: "rgb(255, 0, 0)",
        text: "Lmax >87 dBA",
        value: "Square",
      },
      {
        legendType: "icon",
        colour: "rgb(85, 255, 0)",
        text: "Leq, 24hr >60 dBA",
        value: "Square",
      },
      {
        legendType: "icon",
        colour: "rgb(0, 197, 255)",
        text: "Leq, 8hr >55 dBA",
        value: "Square",
      },
    ],
    "noise-off-airport-operation": [
      {
        legendType: "icon",
        colour: "#FFF",
        text: "Off-airport airborne operational rail noise criteria",
        value: "SquareFill",
      },
      {
        legendType: "icon",
        colour: "rgb(255, 170, 0)",
        text: "Lmax <80 dBA",
        value: "Square",
      },
      {
        legendType: "icon",
        colour: "rgb(197, 0, 255)",
        text: "Leq, 9hr <55 dBA",
        value: "Square",
      },
    ],
    "noise-stabling-impact": [
      {
        legendType: "icon",
        colour: "#f6fc40",
        text: "Exceedance of NMLs 0-10 dB",
        value: "SquareFill",
      },
    ],
  },
  mapComponentConfig: {
    moduleComponentName: "Mapbox",
    mapboxStyle: "mapbox://styles/create-anz/ckfzatylg0f0719mmwrtzlldc",
    customAttribution: "Indicative only, subject to design development",
    initialMapState: {
      locationOptions: {
        zoom: 11,
        center: [150.73, -33.8483],
        pitch: 0,
        bearing: 0,
      },
      layerOptions: {
        visible: [
          "services-facility",
          "stabling-facility",
          "design-site-names",
          "Proposed-metro-station",
          "Proposed-metro-alignment-In-cutting",
          "Proposed-metro-alignment-Cut-Cover",
          "Proposed-metro-alignment-Bridge-Viaduct",
          "Proposed-metro-alignment-Surface",
          "Proposed-metro-alignment-Tunnel",
          "Western-Sydney-International",
          "airport-label",
          "airport-symbol",
        ],
      },
    },
    basemaps: {
      primary: {
        imageName: "primary",
        layerId: "",
      },
      alternate: {
        imageName: "alternate",
        layerId: "mapbox-satellite-imagery",
      },
    },
  },
  popups: [
    {
      id: "operations-montage-popup",
      layerId: "operations-photo-montage-points",
      mouseEvent: "click",
      featureProps: [
        {
          featureId: "view-e-cnr-station-queen",
          moduleComponentName: "Thumbnail",
          moduleProps: {
            columnProps: { className: "p-0" },
            startingLightboxIndex: 0,
            isOpaqueUntilHover: false,
            fileName: "view-east-corner-station-queen",
            lightboxSources: [
              {
                type: "image",
                title: "View east from the corner of Station and Queen Street",
                caption:
                  "Operation: View east from the corner of Station and Queen Street (indicative only, subject to design development).",
                fileName: "view-east-corner-station-queen",
              },
            ],
          },
        },
        {
          featureId: "view-e-traminer-gr",
          moduleComponentName: "Thumbnail",
          moduleProps: {
            columnProps: { className: "p-0" },
            startingLightboxIndex: 0,
            isOpaqueUntilHover: false,
            fileName: "view-east-traminer-grove",
            lightboxSources: [
              {
                type: "image",
                title: "View east from Traminer Grove",
                caption:
                  "Operation: View east from Traminer Grove (indicative only, subject to design development).",
                fileName: "view-east-traminer-grove",
              },
            ],
          },
        },
        {
          featureId: "view-ne-kent-rd",
          moduleComponentName: "Thumbnail",
          moduleProps: {
            columnProps: { className: "p-0" },
            startingLightboxIndex: 0,
            isOpaqueUntilHover: false,
            fileName: "view-northeast-kent-rd",
            lightboxSources: [
              {
                type: "image",
                title:
                  "View northeast from Kent Road towards Orchard Hills Station",
                caption:
                  "Operation: View northeast from Kent Road towards Orchard Hills Station (indicative only, subject to design development).",
                fileName: "view-northeast-kent-rd",
              },
            ],
          },
        },
        {
          featureId: "view-w-luddenham-rd",
          moduleComponentName: "Thumbnail",
          moduleProps: {
            columnProps: { className: "p-0" },
            startingLightboxIndex: 0,
            isOpaqueUntilHover: false,
            fileName: "view-west-luddenham-road",
            lightboxSources: [
              {
                type: "image",
                title: "View west from Luddenham",
                caption:
                  "Operation: View west from Luddenham Road (indicative only, subject to design development).",
                fileName: "view-west-luddenham-road",
              },
            ],
          },
        },
        {
          featureId: "view-sw-luddenham-rd-png",
          moduleComponentName: "Thumbnail",
          moduleProps: {
            columnProps: { className: "p-0" },
            startingLightboxIndex: 0,
            isOpaqueUntilHover: false,
            fileName: "view-southwest-luddenham-road-png",
            lightboxSources: [
              {
                type: "image",
                title: "View southwest along Luddenham",
                caption:
                  "Operation: View southwest along Luddenham Road (indicative only, subject to design development).",
                fileName: "view-southwest-luddenham-road-png",
              },
            ],
          },
        },
        {
          featureId: "view-sw-luddenham-rd-jpg",
          moduleComponentName: "Thumbnail",
          moduleProps: {
            columnProps: { className: "p-0" },
            startingLightboxIndex: 0,
            isOpaqueUntilHover: false,
            fileName: "view-southwest-luddenham-road-jpg",
            lightboxSources: [
              {
                type: "image",
                title: "View southwest from Luddenham",
                caption:
                  "Operation: View southwest from Luddenham Road (indicative only, subject to design development).",
                fileName: "view-southwest-luddenham-road-jpg",
              },
            ],
          },
        },
        {
          featureId: "view-w-st-marys-stn-platform",
          moduleComponentName: "Thumbnail",
          moduleProps: {
            columnProps: { className: "p-0" },
            startingLightboxIndex: 0,
            isOpaqueUntilHover: false,
            fileName: "view-west-st-marys-platform",
            lightboxSources: [
              {
                type: "image",
                title: "View west from St Marys metro station Platform",
                caption:
                  "Operation: View west from St Marys metro station Platform (indicative only, subject to design development).",
                fileName: "view-west-st-marys-platform",
              },
            ],
          },
        },
      ],
    },
    {
      id: "construction-montage-popup",
      layerId: "construction-photo-montage-points",
      mouseEvent: "click",
      featureProps: [
        {
          featureId: "view-w-station-st",
          moduleComponentName: "Thumbnail",
          moduleProps: {
            columnProps: { className: "p-0" },
            startingLightboxIndex: 0,
            isOpaqueUntilHover: false,
            fileName: "view-west-station-street-st-marys",
            lightboxSources: [
              {
                type: "image",
                title: "View west from Station Street St Marys",
                caption:
                  "Construction: View west from Station Street St Marys (indicative only, subject to design development).",
                fileName: "view-west-station-street-st-marys",
              },
            ],
          },
        },
        {
          featureId: "view-se-kent-rd",
          moduleComponentName: "Thumbnail",
          moduleProps: {
            columnProps: { className: "p-0" },
            startingLightboxIndex: 0,
            isOpaqueUntilHover: false,
            fileName: "view-southeast-kent-rd-acoustic",
            lightboxSources: [
              {
                type: "image",
                title:
                  "View southeast along Kent Road showing potential acoustic shed",
                caption:
                  "Construction: View southeast along Kent Road showing potential acoustic shed (indicative only, subject to design development).",
                fileName: "view-southeast-kent-rd-acoustic",
              },
            ],
          },
        },
      ],
    },
  ],
  sites: [
    {
      id: "stMarysStation",
      label: "St Marys metro station",
      locationOptions: {
        zoom: 15,
        center: [150.775732202, -33.7625453181],
        pitch: 0,
        bearing: 0,
      },
    },
    {
      id: "claremontServices",
      label: "Claremont Meadows services facility",
      locationOptions: {
        zoom: 15,
        center: [150.754387, -33.769132],
        pitch: 0,
        bearing: 0,
      },
    },
    {
      id: "orchardStation",
      label: "Orchard Hills Station",
      locationOptions: {
        zoom: 15,
        center: [150.751706789, -33.78911097],
        pitch: 0,
        bearing: 0,
      },
    },
    {
      id: "stablingFacility",
      label: "Stabling and maintenance facility",
      locationOptions: {
        zoom: 15,
        center: [150.753072, -33.801501],
        pitch: 0,
        bearing: 0,
      },
    },
    {
      id: "luddenhamStation",
      label: "Luddenham Station",
      locationOptions: {
        zoom: 15,
        center: [150.740631203, -33.8392336782],
        pitch: 0,
        bearing: 0,
      },
    },
    {
      id: "airportParkStation",
      label: "Airport Business Park Station",
      locationOptions: {
        zoom: 15,
        center: [150.738031629, -33.8816690445],
        pitch: 0,
        bearing: 0,
      },
    },
    {
      id: "airportTerminalStation",
      label: "Airport Terminal Station",
      locationOptions: {
        zoom: 15,
        center: [150.720905474, -33.8892538681],
        pitch: 0,
        bearing: 0,
      },
    },
    {
      id: "bringellyServiceFacility",
      label: "Bringelly Services Facility",
      locationOptions: {
        zoom: 15,
        center: [150.724375, -33.907259],
        pitch: 0,
        bearing: 0,
      },
    },
    {
      id: "airportCoreStation",
      label: "Aerotropolis Station",
      locationOptions: {
        zoom: 15,
        center: [150.735730339, -33.9241434062],
        pitch: 0,
        bearing: 0,
      },
    },
  ],
};

export const navConfig = {
  items: {
    nonCollapsing: [
      {
        item: "logo",
        filename: "logo",
      },
    ],
    collapsing: [
      { item: "title", text: "Sydney Metro – Western Sydney Airport" },
      { item: "icon", icon: "HouseFill", link: "/" },
      {
        item: "page-link",
        text: "Interactive map",
        link: "/interactive-map",
      },
      {
        item: "page-link",
        text: "Planning documents",
        link: "/planning-documents",
      },
      {
        item: "page-link",
        text: "Virtual information room",
        link: "/virtual-information-room",
      },
      {
        item: "page-link",
        text: "Make a submission",
        link: "/make-a-submission",
      },
      {
        item: "page-link",
        text: "Images & videos",
        link: "/images-and-videos",
      },
      {
        item: "modal-link",
        text: "Contact us",
        modalId: "contact-us",
      },
      {
        item: "icon",
        icon: "ShareFill",
        modalId: "share",
      },
    ],
  },
};

export const footerConfig = {
  leftContent: {
    copyrightText: "© 2020 NSW Government | ",
    links: [
      {
        text: "Privacy Policy",
        path: "https://www.sydneymetro.info/privacy-policy",
      },
    ],
  },
  rightContent: ["GoogleTranslate"],
};
