// Lightbox action types.
export const INCREMENT_LIGHTBOX_KEY = "INCREMENT_LIGHTBOX_KEY";
export const SET_LIGHTBOX_SOURCES = "SET_LIGHTBOX_SOURCES";
export const SET_LIGHTBOX_SOURCE_INDEX = "SET_LIGHTBOX_SOURCE_INDEX";
export const TOGGLE_LIGHTBOX_STATE = "TOGGLE_LIGHTBOX_STATE";

// Modal action types.
export const SET_ACTIVE_MODAL = "SET_ACTIVE_MODAL";
export const SET_MODAL_TEMPLATES = "SET_MODAL_TEMPLATES";

// Virtual Consultation Room action types.
export const SET_ACTIVE_SCENE_ID = "SET_ACTIVE_SCENE_ID";
