export const constructionPhotos = {
  type: "FeatureCollection",
  name: "Data",
  features: [
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [150.7781611111, -33.7632],
      },
      properties: {
        Name: "view-west-station-street-st-marys.jpg",
        Description: "View west from Station Street St Marys",
        DateTime: "2019:06:11 10:19:17",
        Direction: 270,
        id: "view-w-station-st",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [150.750408, -33.783632],
      },
      properties: {
        Name: "view-southeast-kent-rd-acoustic.jpg",
        Description:
          "View southeast along Kent Road showing potential acoustic shed",
        DateTime: "2019:09:04 13:53:42",
        Direction: -9999,
        id: "view-se-kent-rd",
      },
    },
  ],
};

export const operationPhotos = {
  type: "FeatureCollection",
  name: "Data",
  features: [
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [150.7751483333, -33.7626583333],
      },
      properties: {
        Name: "view-east-corner-station-queen.jpg",
        Description: "View east from the corner of Station and Queen Street",
        DateTime: "2019:09:04 12:01:50",
        Direction: 57,
        id: "view-e-cnr-station-queen",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [150.74531, -33.79889],
      },
      properties: {
        Name: "view-east-traminer-grove.png",
        Description: "View east from Traminer Grove",
        DateTime: "2019:09:04 16:40:49",
        Direction: -9999,
        id: "view-e-traminer-gr",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [150.7503416667, -33.7891283333],
      },
      properties: {
        Name: "view-northeast-kent-rd.jpg",
        Description:
          "View northeast from Kent Road towards Orchard Hills Station",
        DateTime: "2019:06:11 14:56:04",
        Direction: 144,
        id: "view-ne-kent-rd",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [150.7435433333, -33.84081],
      },
      properties: {
        Name: "view-west-luddenham-road.jpg",
        Description: "View west along Luddenham",
        DateTime: "2019:09:04 14:30:31",
        Direction: 274,
        id: "view-w-luddenham-rd",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [150.7431816667, -33.8409283333],
      },
      properties: {
        Name: "view-southwest-luddenham-road-png.png",
        Description: "View southwest along Luddenham",
        DateTime: "2019:09:04 14:31:17",
        Direction: 240,
        id: "view-sw-luddenham-rd-png",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [150.764225, -33.8078433333],
      },
      properties: {
        Name: "view-southwest-luddenham-road-jpg.jpg",
        Description: "View southwest from Luddenham",
        DateTime: "2019:09:04 14:04:49",
        Direction: 302,
        id: "view-sw-luddenham-rd-jpg",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [150.7771383333, -33.7623866667],
      },
      properties: {
        Name: "view-west-st-marys-platform.jpg",
        Description: "View west from St Marys metro station platform",
        DateTime: "2019:09:04 11:54:20",
        Direction: 257,
        id: "view-w-st-marys-stn-platform",
      },
    },
  ],
};
