// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-images-and-videos-js": () => import("./../../../src/pages/images-and-videos.js" /* webpackChunkName: "component---src-pages-images-and-videos-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-interactive-map-js": () => import("./../../../src/pages/interactive-map.js" /* webpackChunkName: "component---src-pages-interactive-map-js" */),
  "component---src-pages-make-a-submission-js": () => import("./../../../src/pages/make-a-submission.js" /* webpackChunkName: "component---src-pages-make-a-submission-js" */),
  "component---src-pages-planning-documents-js": () => import("./../../../src/pages/planning-documents.js" /* webpackChunkName: "component---src-pages-planning-documents-js" */),
  "component---src-pages-virtual-information-room-js": () => import("./../../../src/pages/virtual-information-room.js" /* webpackChunkName: "component---src-pages-virtual-information-room-js" */)
}

